import React from "react";
import Layout from "../components/layout";
import "../styles/pages/policy.scss";
export default function TermsAndConditions() {
  return (
    <Layout title="Terms and Conditions">
      <h1 className="page-title">Terms and Conditions</h1>
      <article>
        <section className="section">
          <h2>1. Acceptance of Terms:</h2>
          <p>
            By using the services provided by Nookampalayam Link Road Residents
            Welfare Association, residents agree to comply with and be bound by
            these Terms and Conditions. If residents do not agree with any part
            of these terms, they may not access the services.
          </p>
        </section>

        <section className="section">
          <h2>2. Membership:</h2>
          <p>
            Residents must be members of the association to access certain
            services. Membership eligibility criteria, fees, and benefits are
            outlined in the association's membership policy.
          </p>
        </section>

        <section className="section">
          <h2>3. Use of Services:</h2>
          <p>
            Residents agree to use the services provided by the association
            responsibly and in accordance with applicable laws and regulations.
            Any misuse of services may result in termination of membership.
          </p>
        </section>

        <section className="section">
          <h2>4. Privacy:</h2>
          <p>
            Residents' privacy is important to us. Please refer to our Privacy
            Policy for information on how we collect, use, and protect your
            personal information.
          </p>
        </section>

        <section className="section">
          <h2>5. Intellectual Property:</h2>
          <p>
            All content and materials provided by the association, including but
            not limited to logos, designs, and documents, are the intellectual
            property of the association and may not be reproduced or used
            without permission.
          </p>
        </section>

        <section className="section">
          <h2>6. Limitation of Liability:</h2>
          <p>
            The association shall not be liable for any indirect, incidental,
            special, or consequential damages arising out of or in any way
            connected with the use of its services.
          </p>
        </section>

        <section className="section">
          <h2>7. Modification of Terms:</h2>
          <p>
            The association reserves the right to modify these Terms and
            Conditions at any time. Any changes will be effective immediately
            upon posting on the association's website. Residents are responsible
            for reviewing these terms periodically to stay informed of any
            updates.
          </p>
        </section>

        <section className="section">
          <h2>8. Contact Us:</h2>
          <p>
            If you have any questions about these Terms and Conditions, please
            contact us at{" "}
            <a href="mailto:nlrrwa2018@gmail.com"> nlrrwa2018@gmail.com</a>.
          </p>
        </section>

        <p className="last-updated">
          These Terms and Conditions were last updated on 12-Mar-2024.
        </p>
      </article>
    </Layout>
  );
}
